<template>
	<v-card>
		<v-card-title>
			<v-spacer></v-spacer>
		    <v-text-field
		        v-model="search"
		        append-icon="search"
		        label="Search"
		        single-line
		        hide-details
		    ></v-text-field>
		</v-card-title>
		<v-data-table
			:custom-filter="filter"
			:search="search"
			:headers="headers"
			:items="data"
			:items-per-page="5"
			:loading="loading"
			class="elevation-1"
			:rows-per-page-text="t('Rows per page')"
            :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
		>
            <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">
					<div style="width: 100%; height: 100%; padding-top: 15px;">
						{{ format_period(props.item) }}
					</div>
                </td>
                <td class="text-xs-left no-wrap">
					<div style="width: 100%; height: 100%; padding-top: 15px">
						{{ props.item.money }}
					</div>
				</td>
                <td class="text-xs-left no-wrap">
                    <v-flex
                        style="align-items: start; display: flex; padding: 0; margin: 0; height: 100%"
                    >
                        <div
                            style="margin-right: 10px; padding-top: 15px;"
                            v-if="props.item.cards_count"
                        >
                            {{ t('Total transactions') }}({{ props.item.cards_count }})
							<template v-if="props.item.active">
								<br/>
								{{ t('Card numbers (amount of money):') }}
							</template>
                        </div>
                        <v-list-group
                            style="display: inline; padding-top: 14px;"
                            v-if="props.item.cards && props.item.cards.length > 0 && props.item.cards[0]!=='None'"
							v-model="props.item.active"
                        >
                            <div
                                style="padding: 0; margin: 0;"
                                v-for="([card, spend_money], i) in props.item.cards"
                                :key="i"
                            >
                                <v-list-tile-sub-title>
                                    <a
                                        :href="`/cards/${card}/details/`"
                                        class="url-text"
                                    >
                                        {{ card }}
                                    </a>
									 ({{ spend_money }})
								</v-list-tile-sub-title>
							</div>
                        </v-list-group>
                        <v-icon v-else class="grey--text" style="padding-top: 12px">remove</v-icon>
                    </v-flex>
                </td>
            </template>
            <template slot="pageText" slot-scope="item">
                {{t('Elements')}} {{item.pageStart}} - {{item.pageStop}}, {{t('total')}}: {{item.itemsLength}}
            </template>
			<template v-slot:footer>
				<td :colspan="headers.length">
					<total
						:totalCards="totalCards"
						:totalMoney="totalMoney"
					></total>
				</td>
			</template>
		</v-data-table>
	</v-card>
</template>
<script>
	import Total from "./Total.vue";

	export default {
		name: "ReportsByPeriod",
		components: {Total},
		props: {  
			data: Array,
			totalCards: Number,
			totalMoney: Number,
			loading: Boolean,
		},
		data() {
			return {
				search: '',
				headers: [
					{
						text: this.$translate.locale['Payment period'],
						value: 'payment_period_name',
					},
					{
						text: this.$translate.locale['Finance'],
						value: 'money',
					},
					{
						text: this.$translate.locale['Cards of subscribers'],
						value: 'cards',
					},
				]
			}
		},
		methods: {
			filter(items, search, filter) {
				search = search.toLowerCase();
				return items.filter(item => {
					let period = this.format_period(item);

					for (let card_data of item.cards)
						for (let x of card_data)
							if (x.toString().includes(search))
								return true;

					for (let a of [period, item.money]) {
						if (a.toString().toLowerCase().includes(search)) return true;
					}
				});
			},
			format_period(item) {
				let {payment_period_name, packet_header} = item;
				return `${payment_period_name} (${packet_header})`;
			}
		}
	}
</script>

