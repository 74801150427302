<template>
	<strong>
		Total cards: {{ totalCards }}, total money: {{ totalMoney }}
	</strong>
</template>
<script>
	export default {
		props: {
			totalCards: Number,
			totalMoney: Number,
		}
	}
</script>
