<template>
    <v-card>
		<v-card-title>
			<v-spacer></v-spacer>
		    <v-text-field
		        v-model="search"
		        append-icon="search"
		        label="Search"
		        single-line
		        hide-details
		    ></v-text-field>
		</v-card-title>
        <v-data-table
			:search="search"
            :headers="headers"
            :items="data"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            :rows-per-page-text="t('Rows per page')"
            :rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
        >
            <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">
					<div style="width: 100%; height: 100%; padding-top: 15px;">
						<router-link 
							v-if="showResellerHref"
							:to="`/resellers/${props.item.pk}/details/`"
							class="url-text"
						>
							{{ props.item.name }} 
						</router-link>
						<template
							v-else
						>
							{{ props.item.name }}
						</template>
					</div>
                </td>
                <td class="text-xs-left no-wrap">
					<div style="width: 100%; height: 100%; padding-top: 15px">
						{{ props.item.money }}
					</div>
				</td>
                <td class="text-xs-left no-wrap">
                    <v-flex
                        style="display: flex; padding: 0; margin: 0; height: 100%"
                    >
                        <div
                            style="margin-right: 10px; padding-top: 15px;"
                            v-if="props.item.cards_count"
                        >
                            {{ t('Total transactions') }}({{ props.item.cards_count }})
							<template v-if="props.item.active">
								<br/>
								{{ t('Card numbers (amount of money):') }}
							</template>
                        </div>
                        <v-list-group
                            style="display: inline; padding-top: 14px;"
                            v-if="props.item.cards && props.item.cards.length > 0 && props.item.cards[0]!=='None'"
							v-model="props.item.active"
                        >
                            <div
                                style="padding: 0; margin: 0;"
                                v-for="([card, spend_money], i) in props.item.cards"
                                :key="i"
                            >
                                <v-list-tile-sub-title>
                                    <a
                                        :href="`/cards/${card}/details/`"
                                        class="url-text"
                                    >
                                        {{ card }}
                                    </a>
									 ({{ spend_money }})
									</v-list-tile-sub-title>
								</div>
                        </v-list-group>
                        <v-icon v-else class="grey--text" style="padding-top: 12px">remove</v-icon>
                    </v-flex>
                </td>
            </template>
            <template slot="pageText" slot-scope="item">
                {{t('Elements')}} {{item.pageStart}} - {{item.pageStop}}, {{t('total')}}: {{item.itemsLength}}
            </template>
            <template slot="no-data">
                <v-subheader>{{t('No data available')}}</v-subheader>
            </template>
			<template slot="footer">
				<td>
					<total
						:totalCards="totalCards"
						:totalMoney="totalMoney"
					></total>
				</td>
			</template>
        </v-data-table>
    </v-card>
</template>

<script>
	import Total from "./Total.vue";

    export default {
        name: "ReportsCardsAndFinances",
		components: {Total},
        props: {
            data: Array,
			totalCards: Number,
			totalMoney: Number,
            loading: Boolean,
        },
        data() {
            return {
				search: '',
                headers: [
                    {
                        text:  this.$translate.locale['Reseller'],
                        align: 'left',
                        value: 'name',
                    },
                    {
                        text: this.$translate.locale['Finance'],
                        value: 'money'
                    },
                    {
                        text: this.$translate.locale['Cards of subscribers'], 
                        value: 'cards',
                    },
                ],
            }
        },
		computed: {
			showResellerHref() {
				return this.$store.getters.isAdmin || this.$store.getters.isOwner
			}
		}
    }
</script>

<style>
    .url-text {
        color: #007bff;
        text-decoration: none;
    }
    .cards-wrapper {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100px;
    }

    .cards-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

    }
    .v-list__group__header__append-icon{
        padding-left: 0 !important;
    }
</style>
