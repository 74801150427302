<template>
    <v-content color="primary">
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                top
        >
            {{ t(text) }}
            <v-btn
                    flat
                    @click="snackbar = false"
            >
                {{t('Close')}}
            </v-btn>
        </v-snackbar>
        <v-container fluid id="main-container" grid-list-md>
            <v-layout row wrap>
                <v-flex xs12 justify-center>
                    <v-form @submit.prevent="getData" ref="form">
                        <v-card class="px-3 mb-3 pb-3">
                            <v-layout class="d-flex">
                                <v-flex cols="6" lg="6" class="mr-5">
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="dateLt"
                                                :label="t('from')"
                                                :hint="t('yyyy-mm-dd format')"
                                                persistent-hint
                                                prepend-icon="event"
                                                @blur="dateLt = parsedate(dateLt)"
                                                v-on="on"
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="dateLt" no-title :max="dateRt" @input="menu1 = false"></v-date-picker>
                                </v-menu>
                                </v-flex>
                                <v-flex cols="6" lg="6">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="dateRt"
                                                :label="t('to')"
                                                :hint="t('yyyy-mm-dd format')"
                                                persistent-hint
                                                prepend-icon="event"
                                                @blur="dateRt = parsedate(dateRt)"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="dateRt" no-title  :min="dateLt" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex cols="1" lg="1">
                                    <v-btn @click="getData" color="success" :disabled="!dateLt && !dateRt" dark style="margin-top: 20px;">{{t('Apply')}}</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-form>
                </v-flex>
                <v-flex xs12>
					<v-tabs grow v-model="tabValue">
						<template v-if="resellerOrManager">
							<v-tab href="#report-by-period">
								{{ t('Payment periods') }}
							</v-tab>
							<v-tab href="#report-card">
								{{ t('Finance') }}
							</v-tab>
						</template>
						<template v-else>
							<v-tab href="#report-card">
								{{ t('User finances') }}
							</v-tab>
							<v-tab href="#report-by-period">
								{{ t('Payment periods') }}
							</v-tab>
						</template>
					</v-tabs>
					<v-tabs-items v-model="tabValue" touchless>
						<template v-if="resellerOrManager">
							<v-tab-item value="report-by-period">
								<reports-by-period
									:data="details.objects"
									:loading="loading"
									:totalMoney="totalMoney"
									:totalCards="totalCards"
								></reports-by-period>
							</v-tab-item>
							<v-tab-item value="report-card">
								<reports-cards-and-finances
									:data="details.objects"
									:loading="loading"
									:totalMoney="totalMoney"
									:totalCards="totalCards"
								></reports-cards-and-finances>
							</v-tab-item>
						</template>
						<template v-else>
							<v-tab-item value="report-card">
								<reports-cards-and-finances
									:data="details.objects"
									:loading="loading"
									:totalMoney="totalMoney"
									:totalCards="totalCards"
								></reports-cards-and-finances>
							</v-tab-item>
							<v-tab-item value="report-by-period">
								<reports-by-period
									:data="details.objects"
									:loading="loading"
									:totalMoney="totalMoney"
									:totalCards="totalCards"
								></reports-by-period>
							</v-tab-item>
						</template>
					</v-tabs-items>
                    <!-- <v-tabs grow @change="tabValue != 'report-logs' ? getData() : ''" v-model="tabValue">
                        <v-tab href="#report-subs">
                            <v-icon left>mdi-account</v-icon>
                            {{t('Subscribers')}}
                        </v-tab>
                        <v-tab href="#report-card">
                            <v-icon left>mdi-lock</v-icon>
                            {{t('Cards')}}
                        </v-tab>
                        <v-tab href="#report-finance">
                            <v-icon left>mdi-access-point</v-icon>
                           {{t('Finance')}}
                        
                        <v-tab href="#report-logs">
                            <v-icon left>mdi-access-point</v-icon>
                            {{t('Logs')}}
                        </v-tab>
                        <v-tab href="#report-cards-reseller">
                            <v-icon left>mdi-access-point</v-icon>
                            {{t('Reseller cards')}}
                        </v-tab>
                        <v-tab-item value="report-subs">
                            <reports-subscribers :data="details.objects" :loading="loading" :total="details.total" :kind="t('subscribers')"/>
                        </v-tab-item>
                        <v-tab-item value="report-card">
                            <reports-cards-and-finances
                                :data="details.objects"
                                :loading="loading"
                            ></reports-cards-and-finances>
                            <reports-subscribers :data="details.objects" :loading="loading" :total="details.total" :kind="t('cards of subscribers')"/>
                        </v-tab-item>
                        <v-tab-item value="report-finance">
                            <reports-subscribers :data="details.objects" :loading="loading" :total="details.total" :kind="t('finance')"/>
                        </v-tab-item>
                        <v-tab-item value="report-logs">
                            <reports-subscriber-logs :dateLt="dateLt" :dateRt="dateRt"/>
                        </v-tab-item>
                        <v-tab-item value="report-cards-reseller">
                            <reports-reseller :data="details.objects" :loading="loading" :total="details.total" :kind="t('cards')"/>
                        </v-tab-item>
                    </v-tabs> -->
                </v-flex>
            </v-layout>
        </v-container>

    </v-content>

</template>

<script>
    import axios from 'axios';
    import ReportsCardsAndFinances from "../../components/Reports/ReportsCardsAndFinances";
    import ReportsByPeriod from "../../components/Reports/ReportsByPeriod"

    export default {
        name: "Reports",
        components: {ReportsCardsAndFinances, ReportsByPeriod},
        data: vm => ({
            dateLt: '',
            dateRt: '',
			startOfMonth: '',
			endOfMonth: '',
			tabValue: '',
            dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
            menu1: false,
            menu2: false,
            details:[],
            reseller:'',
            searchLogs: '',
            subscriber:'',
            loading: true,
            snackbar: false,
            text: 'Oops... Something went wrong',
            timeout: 5000,
            rules: {
                required: value => !!value || this.$translate.locale['Required.'],
                username: value => (value.length >= 4 && value.length <= 150) ||this.$translate.locale[ 'Username between the 4 - 150 characters'],
                counter: value => value.length >= 8 || this.$translate.locale['Min 8 characters'],
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
                },
            },
        }),
        methods:{
			setDefaultDates() {
				this.dateLt = this.$moment().startOf('month').format('YYYY-MM-DD');
				this.dateRt = this.$moment().endOf('month').format('YYYY-MM-DD');
			},
            getData(){
				this.details = [];
                this.loading = true;
                axios.post(`/api/${this.tabValue}/`,{dateLt:this.dateLt,dateRt:this.dateRt,reseller:this.reseller})
                    .then((response) => {
                        if (response.status === 200) {
                            this.details = response.data;
                            this.loading = false;
                        }
                    }).catch((error) => {
						if(error.response.status === 400){
							this.text = "Incorrect data";
							this.snackbar = true;
						}else{
							this.text = "Connection error";
							this.snackbar = true;
						}
                });
            },
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },
        computed: {
			resellerOrManager() {
				return this.$store.getters.isReseller || this.$store.getters.isManager;
			},
			totalCards() {
				return this.details.total_cards
			},
			totalMoney() {
				return this.details.total_money
			}
        },
        // watch: {
        //     dateLt: function () {
        //         if(this.dateLt && this.dateRt){
        //             this.getData();
        //         }
        //     },
        //     dateRt: function () {
        //         if(this.dateLt && this.dateRt){
        //             this.getData();
        //         }
        //     }
        // },
		watch: {
			tabValue(o, n) {
				this.getData();
			}
		},
        beforeCreate() {
            if (!this.$store.getters.loggedIn) {
                this.$router.push('/')
            }
        },
		beforeMount() {
			this.tabValue = this.resellerOrManager ? 'report-by-period' : 'report-card';
		},
        mounted() {
			this.setDefaultDates();
            this.getData();
        }
    }
</script>

<style scoped>

</style>
